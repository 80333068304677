<app-framework>
    <div head class="landing-header-content flex flex-column lg:flex-row column-gap-4 row-gap-4">
        <div class="header-text flex flex-column justify-content-end">
            <h1>Esschem Europe</h1>
            <p>A manufacturer of acrylic powder and liquid systems and professional curable gel systems
                for the
                Cosmetic fingernail industries and supplies specialised materials for the Dental,
                Medical and
                Industrial Coatings industries.
            </p>
            <div class="flex flex-row justify-content-end gap-1 flex-wrap">
                <a mat-raised-button class="flex" [href]="dentalBrochure" target="_blank">
                    <fa-icon [icon]="faBookOpen"></fa-icon> Dental Brochure
                </a>
                <a mat-raised-button class="flex" [href]="cosmeticBrochure" target="_blank">
                    <fa-icon [icon]="faBookOpen"></fa-icon> Cosmetic Brochure
                </a>
            </div>
        </div>
        <mat-card class="flex header-image header-card justify-content-center container reveal fade-right">
            <img class="lazy" alt="feature" src="../../assets/layout/images/pages/landing/solutions pic.jpg"
                data-src="../../assets/layout/images/pages/landing/solutions pic.jpg"
                data-srcset="../../assets/layout/images/pages/landing/solutions pic.jpg" />
        </mat-card>
    </div>

    <ng-container body>
        <div class="landing-meetEsschemEurope">
            <div #meetEsschemEurope id="meetEsschemEurope" class="section-title">
                <h1>Meet Esschem Europe</h1>
                <span>Manufacturer of acrylic, UV Gel systems and specialised materials</span>
            </div>
            <div class="grid">
                <div class="col-12 md:col-6 lg:col-3">
                    <div class="card widget-feature-box">
                        <fa-icon [icon]="faExpandAlt" size="6x"></fa-icon>
                        <h1>Expansive</h1>
                        <span>Esschem Europe, along with our affiliated companies in the USA, have a huge range
                            of materials which extend to specialised purified monomers, glass fillers for
                            restorative and composite dentistry, polymer systems for prosthetic dentistry,
                            orthopaedic bone cement, audiological acrylics and customised acrylic beads.</span>
                    </div>
                </div>
                <div class="col-12 md:col-6 lg:col-3">
                    <div class="card widget-feature-box">
                        <fa-icon [icon]="faCertificate" size="6x"></fa-icon>
                        <h1>Certified</h1>
                        <span>Esschem Europe is certified to both ISO 22716:2007 Cosmetics GMP and ISO
                            9001:2015. As such our products are manufactured under GMP conditions and comply
                            with the demands of Regulation (EC) No 1223/2009 on Cosmetic Products.
                        </span>
                    </div>
                </div>
                <div class="col-12 md:col-6 lg:col-3">
                    <div class="card widget-feature-box">
                        <fa-icon [icon]="faHandshake" size="6x"></fa-icon>
                        <h1>Regulated</h1>
                        <span>Esschem Europe fully comply with Regulation (EC) No 1907/2006 concerning the
                            Registration, Evaluation, Authorisation and restriction of Chemicals (REACH). This
                            is the system for controlling chemicals in the EU.
                        </span>
                    </div>
                </div>
                <div class="col-12 md:col-6 lg:col-3">
                    <div class="card widget-feature-box">
                        <fa-icon [icon]="faHeadset" size="6x"></fa-icon>
                        <h1>Premium Support</h1>
                        <span>Esschem Europe welcome the opportunity to work with customers on a confidential
                            basis, on the products and packaging requirements for their brands. We offer a
                            comprehensive, quick turnaround service.
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>

        <div #features id="features" class="landing-features">
            <div class="features-title">
                <h1>Products</h1>
                <span>Cosmetic, Dental & Medical</span>
            </div>
            <div #cosmetic id="cosmetic" class="feature-row">
                <div class="feature-text">
                    <h2>Cosmetic</h2>
                    <span>For over 20 years, Esschem Europe has been a leading name in the professional cosmetic
                        Industry.</span>
                    <mat-divider class="my-2"></mat-divider>
                    <div class="flex flex-row justify-content-center lg:justify-content-end flex-wrap gap-2 lg:gap-1">
                        <button mat-raised-button color="primary" [routerLink]="['services/cosmetic']">Discover more</button>
                        <a mat-raised-button class="flex" [href]="cosmeticBrochure" target="_blank">
                            <fa-icon [icon]="faBookOpen"></fa-icon> Cosmetic Brochure
                        </a>
                    </div>
                </div>
                <div class="feature-image container reveal fade-right">
                    <img class="lazy" alt="feature" src="../../assets/layout/images/pages/landing/Gels home pic.jpg"
                        data-src="../../assets/layout/images/pages/landing/Gels home pic.jpg"
                        data-srcset="../../assets/layout/images/pages/landing/Gels home pic.jpg" />
                </div>
            </div>
            <div #dental id="dental" class="feature-row row-reverse">
                <div class="feature-text">
                    <h2>Dental</h2>
                    <span>Esschem has been involved in dental materials for prosthetics for over 60
                        years.</span>
                    <mat-divider class="my-2"></mat-divider>
                    <div class="flex flex-row justify-content-center lg:justify-content-end flex-wrap gap-2 lg:gap-1">
                        <button mat-raised-button color="primary" [routerLink]="['services/dental']">Discover more</button>
                        <a mat-raised-button class="flex" [href]="dentalBrochure" target="_blank">
                            <fa-icon [icon]="faBookOpen"></fa-icon> Dental Brochure
                        </a>
                    </div>
                </div>
                <div class="feature-image container reveal fade-left">
                    <img class="lazy" alt="feature" src="../../assets/layout/images/pages/landing/dental-service.jpg"
                        data-src="../../assets/layout/images/pages/landing/dental-service.jpg"
                        data-srcset="../../assets/layout/images/pages/landing/dental-service.jpg" />
                </div>
            </div>
            <div #medical id="medical" class="feature-row">
                <div class="feature-text">
                    <h2>Medical</h2>
                    <span>
                        Esschem Europe has been a major supplier of bone cement materials for several decades.
                        We manufacture acrylic polymers for use in formulations and work with our clients to
                        produce customised materials within the specification required.
                    </span>
                    <mat-divider class="my-2"></mat-divider>
                    <div class="flex flex-row justify-content-center lg:justify-content-end flex-wrap gap-2 lg:gap-1">
                        <button mat-raised-button color="primary" [routerLink]="['services/medical']">Discover more</button>
                    </div>
                </div>
                <div class="feature-image container reveal fade-right">
                    <img class="lazy" alt="feature" src="../../assets/layout/images/pages/landing/anatomical.jpg"
                        data-src="../../assets/layout/images/pages/landing/anatomical.jpg"
                        data-srcset="../../assets/layout/images/pages/landing/anatomical.jpg" />
                </div>
            </div>
            <div #industrial id="industrial" class="feature-row row-reverse">
                <div class="feature-text">
                    <h2>Industrial</h2>
                    <span>Esschem Europe supply various Urethanes, Monomers, Photoinitiators and Adhesives for Industrial applications such
                        as 3D printing, rapid prototyping, printing inks, and coatings of various types.</span>
                    <mat-divider class="my-2"></mat-divider>
                    <div class="flex flex-row justify-content-center lg:justify-content-start flex-wrap gap-2 lg:gap-1">
                        <button mat-raised-button color="primary" [routerLink]="['services/industrial']">Discover more</button>
                    </div>
                </div>
                <div class="feature-image container reveal fade-left">
                    <img class="lazy" alt="feature" src="../../assets/layout/images/pages/landing/industrial.jpg"
                        data-src="../../assets/layout/images/pages/landing/industrial.jpg"
                        data-srcset="../../assets/layout/images/pages/landing/industrial.jpg" />
                </div>
            </div>
        </div>
        <mat-divider></mat-divider>

        <div id="newsfeed" class="landing-newsfeed">
            <div class="section-title">
                <h1>Latest News</h1>
            </div>
            <app-news-feed [pageSize]="3" [admin]="false"></app-news-feed>
            <div class="flex flex-row justify-content-center">
                <a mat-raised-button color="primary" class="flex" [routerLink]="['/news']">Read more stories</a>
            </div>
        </div>
        <mat-divider></mat-divider>

        <div #affiliates id="affiliates" class="landing-affiliates">
            <div class="section-title">
                <h1>Our affiliates</h1>
            </div>
            <div class="flex justify-content-center flex-wrap">
                <div class="flex flex-grow-1 align-items-center justify-content-center font-bold text-white m-2">
                    <a href="http://cachemspecialty.com/" target="_blank" id="cachemspecialty"></a>
                </div>
                <div class="flex flex-grow-1 align-items-center justify-content-center font-bold text-white m-2">
                    <a href="https://www.esschem.com/" target="_blank" id="esschem"></a>
                </div>
                <div class="flex flex-grow-1 align-items-center justify-content-center font-bold text-white m-2">
                    <a href="https://www.esspac.com/" target="_blank" id="esspac"></a>
                </div>
                <div class="flex flex-grow-1 align-items-center justify-content-center font-bold text-white m-2">
                    <a href="http://www.esstechinc.com/" target="_blank" id="esstechinc"></a>
                </div>
                <div class="flex flex-grow-1 align-items-center justify-content-center font-bold text-white m-2">
                    <a href="http://www.sgiglass.com/" target="_blank" id="sgiglass"></a>
                </div>
            </div>
        </div>
    </ng-container>
</app-framework>