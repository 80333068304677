<mat-sidenav-container class="example-container h-full">
    <mat-sidenav #sidenav mode="side" [(opened)]="opened">
        <button class="block w-8rem" mat-button [routerLink]="['news']">News</button>
        <!-- <button class="block w-8rem" mat-button [routerLink]="['services']">Services</button> -->
    </mat-sidenav>

    <mat-sidenav-content class=" h-screen">
        <mat-toolbar class="nav-bar">
            <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon" (click)="sidenav.toggle()">
                <mat-icon>menu</mat-icon>
            </button>
            <span>Esschem Admin</span>
            <span class="example-spacer"></span>
            <ng-template #anonymousUser>
                <button mat-button (click)="login()">Login</button>
            </ng-template>
            <ng-container *ngIf="appUser; else anonymousUser">
                <button mat-button (click)="logout()">Logout</button>
            </ng-container>
        </mat-toolbar>
        <div class="admin-content">
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>