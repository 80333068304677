import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminNavigationComponent } from './components/admin-navigation/admin-navigation.component';
import { NewsEditorComponent } from './components/news-editor/news-editor.component';
import { NewsComponent } from './components/blog/blog.component';
import { HomeComponent } from './components/home/home.component';
import { LandingNavigationComponent } from './components/landing-navigation/landing-navigation.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { NewsFeedComponent } from './components/news-feed/news-feed.component';
import { AuthGuard } from './guards/auth.guard';
import { AppCrudComponent } from './components/news-crud/app.crud.component';
import { SignInComponent } from './components/authentication/sign-in/sign-in.component';
import { SignUpComponent } from './components/authentication/sign-up/sign-up.component';
import { VerifyEmailComponent } from './components/authentication/verify-email/verify-email.component';
import { ForgotPasswordComponent } from './components/authentication/forgot-password/forgot-password.component';
import { CompanyServiceComponent } from './pages/company-service/company-service.component';

const routes: Routes = [
    {
        path: '',
        children: [
            { path: '', component: LandingPageComponent },
            {
                path: '', component: LandingNavigationComponent,
                children: [
                    {
                        path: "news",
                        children: [
                            { path: "", component: HomeComponent, pathMatch: "full", },
                            { path: ":pagenum", component: HomeComponent, data: { breadcrumb: 'News' } },
                            { path: ":id/:slug", component: NewsComponent, data: { breadcrumb: { skip: true, alias: 'slugName' } } },
                        ],
                        data: { breadcrumb: 'News' }
                    },
                    {
                        path: "services",
                        children: [
                            { path: ":service", component: CompanyServiceComponent, pathMatch: "full", data: { breadcrumb: { alias: 'service' }}}
                        ],
                        data: { breadcrumb: { skip: true }}
                    }
                ],
            },
        ],
        data: { breadcrumb: 'Home' }
    },

    {
        path: 'admin', component: AdminNavigationComponent,
        children: [
            {
                path: "news",
                canActivate: [AuthGuard],
                children: [
                    {
                        path: '',
                        component: AppCrudComponent
                    },
                    {
                        path: 'edit/:id',
                        component: NewsEditorComponent
                    },
                    {
                        path: 'edit',
                        component: NewsEditorComponent
                    }
                ]
            },
            {
                path: "services",
                component: NewsEditorComponent,
                canActivate: [AuthGuard],
            },
            {
                path: "listNews",
                component: NewsFeedComponent,
                canActivate: [AuthGuard],
            },
            {
                path: "auth",
                children: [
                    { path: 'sign-in', component: SignInComponent },
                    { path: 'register-user', component: SignUpComponent },
                    { path: 'forgot-password', component: ForgotPasswordComponent },
                    { path: 'verify-email-address', component: VerifyEmailComponent },
                ]
            },
            { path: "**", redirectTo: 'auth/sign-in' }
        ],
    },

    { path: "**", redirectTo: '/' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
