import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { ServiceInfo } from "../models/service-info";
import { BrochureLinks } from "../models/brochure-links";

@Injectable({
  providedIn: "root",
})
export class CompanyServiceService {
  constructor() {}

  getCompanyService(companyServiceName) {
    switch (companyServiceName) {
      case "cosmetic":
        return of({
          content: [
            {
              title: "Cosmetics",
              html: `<div class="col-lg-8 col-md-8 col-sm-8 col-xs-12"><p><img alt="" class="pyro-image alignment-left" data-pyroimage="true" src="https://www.esschem-europe.com/files/large/b0f33c3d88ba222" style="float: left; height: 600px; width: 800px;"><br>&nbsp;</p><p><b>For over&nbsp;20 years, Esschem Europe has been a trusted name in the professional cosmetic Industry.&nbsp;</b></p><p>Whether you are requiring a UV / LED Gel,&nbsp;&nbsp;an Acrylic powder and liquid system or a range of solutions, all of our products are high quality, made from EU compliant ingredients, fully tested, manufactured and available for sale from our facility in the UK.<br><br>Our research and development team are constantly updating our product and colour range to keep you&nbsp;up to date with the latest developments and trends in the industry.<br><br>We can supply your products in bulk, in filled units or ready for sale, labelled and packaged.&nbsp;</p></div>`,
              isActive: true,
            },
          ],
          brochure: {
            name: "Cosmetic Brochure",
            url: BrochureLinks.Cosmetic.toString(),
          },
          banner: "assets/layout/images/pages/cosmeticBanner.jpg",
        } as ServiceInfo);
      case "dental":
        return of({
          content: [
            {
              title: "Dental",
              html: `<div class="col-lg-8 col-md-8 col-sm-8 col-xs-12"><p style="text-align: justify;">Esschem has been involved in dental materials for prosthetics for over 60 years.<br>Esschem manufactures acrylic bead polymers for a variety of applications from tooth production, to denture base, orthodontics and temporary crown and bridge resins, and offers these as clear polymers&nbsp;or shaded systems. Raw materials for the liquids are also sold, or fully finished formulated monomers are available&nbsp;in bulk or in filled units if required. Esschem does not brand any systems, so&nbsp;the&nbsp;manufacturer is responsible for the&nbsp;final device.<br>&nbsp;<br>Through our&nbsp;affiliates, Esschem Europe can also provide a wide range of raw materials for composite and restorative manufacture. Base oligiomers, diluent monomers, cross linkers and adhesives are the speciality materials, and &nbsp;glass fillers are also in the range. Many products are unique and specially purified for the dental compounders and available in a range of inhibitor packages.&nbsp;<br></p></div>`,
              isActive: true,
            },
          ],
          brochure: {
            name: "Dental Brochure",
            url: BrochureLinks.Dental.toString(),
          },
          banner: "assets/layout/images/pages/dentalBanner.jpg",
        } as ServiceInfo);
      case "industrial":
        return of({
          content: [
            {
              title: "Industrial",
              html: `<div class="col-lg-8 col-md-8 col-sm-8 col-xs-12"><p style="text-align: justify;">Esschem has been involved in dental materials for prosthetics for over 60 years.<br><br>Esschem manufactures acrylic bead polymers for a variety of applications from tooth production, to denture base, orthodontics and temporary crown and bridge resins, and offers these as clear polymers&nbsp;or shaded systems. Raw materials for the liquids are also sold, or fully finished formulated monomers are available&nbsp;in bulk or in filled units if required. Esschem does not brand any systems, so&nbsp;the&nbsp;manufacturer is responsible for the&nbsp;final device.<br>&nbsp;<br>Through our&nbsp;affiliates, Esschem Europe can also provide a wide range of raw materials for composite and restorative manufacture. Base oligiomers, diluent monomers, cross linkers and adhesives are the speciality materials, and &nbsp;glass fillers are also in the range. Many products are unique and specially purified for the dental compounders and available in a range of inhibitor packages.&nbsp;<br></p></div>`,
              isActive: true,
            },
          ],
          brochure: null,
          banner: "assets/layout/images/pages/industrialBanner.jpg",
        } as ServiceInfo);
      case "medical":
        return of({
          content: [
            {
              title: "Medical",
              html: `<div class="col-lg-8 col-md-8 col-sm-8 col-xs-12"><p><img alt="" class="pyro-image alignment-none" data-pyroimage="true" src="https://www.esschem-europe.com/files/thumb/1fa29adb6843298/299" style="width: 299px; float: right;"><br>Esschem Europe has been a major supplier of bone cement materials for several decades. We manufacture acrylic polymers for use in formulations and work with our clients to produce customised materials within the specification required.</p><p>The most trusted source for high grade acrylics for dental, cosmetic and biomedical applications is equally accomplished in the production of polymer substrates and monomers for orthopedic bone cement systems. For the past 30 years, manufacturers of bone cement products have relied on Esschem for their key compounds.</p><p>Formulations for bone cement can vary greatly. The different devices marketed to deliver the cement at the surgical site must meet any number of specifications for viscosity, curing time and more. Over&nbsp;the past quarter century, we have partnered with bio- medical companies to develop products&nbsp; that perform precisely and reliably under the most exacting circumstances. Today, our compounds are being used in cardiac and cranial implants as well as orthopedic applications.</p><p>Computer controlled reactions and special sifters are employed to produce polymers of precise particle size. Esschem is also available to accurately control the addition of Benzoyl peroxide or other catalysts and/ or radio opaque materials such as barium sulphate. Monomers are shipped in dedicated stainless steel drums to assure purity in transit.</p><p>The main types of polymers are the approved formulations:</p><ul> <li>PMMA</li> <li>PMMA/ Styrene</li> <li>PMMA/ Methyl Acrylate</li>
                            </ul><p>The customisation of the beads is our speciality and the beads are customised for:</p><ul> <li>Molecular weight</li> <li>Particle size distribution and mean</li> <li>Residual peroxide from zero up to 5%</li>
                            </ul><p>Esschem Europe will work with our&nbsp;clients to achieve the polymer blend composition required to meet the application.</p></div>`,
              isActive: true,
            },
          ],
          brochure: null,
          banner: "assets/layout/images/pages/medicalBanner.jpg",
        } as ServiceInfo);

      default:
        break;
    }
  }
}
